// React
import React from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Packages
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { useGlobal } from 'reactn';

// Components

const SEOComponent = ({
    faviconMetaTags,
    seoMetaTags,
    title = null,
    description = null,
    locale = 'en',
}) => {
    const [htmlStyle] =
        process.env.NODE_ENV === 'development' ? [''] : useGlobal('htmlStyle');

    return process.env.NODE_ENV === 'development' ? null : (
        <>
            <HelmetDatoCms seo={seoMetaTags} favicon={faviconMetaTags}>
                <html lang={locale} style={htmlStyle} />
                {title && <title>{title}</title>}
                {description && (
                    <meta name="description" content={description} />
                )}
            </HelmetDatoCms>
        </>
    );
};

export default props => {
    const { datoCmsSite } = useStaticQuery(graphql`
        query {
            datoCmsSite {
                faviconMetaTags {
                    ...GatsbyDatoCmsFaviconMetaTags
                }
            }
        }
    `);

    return (
        <SEOComponent
            {...{
                faviconMetaTags: datoCmsSite.faviconMetaTags,
                ...props,
            }}
        />
    );
};
